import React, { useEffect, useState } from "react";
import { Pagehead } from "../../components/Pagehead";
import { PageWrapper } from "../../components/Theme/style";
import imprintImg from "../../../assets/images/ImpressumHeader.png";
import imprintImgMobile from "../../../assets/images/ImpressumHeaderMobile.png";
import constants from "./constants";
import {
  TextWrapper,
  DataH1,
  DataH3,
  TextP,
  StrongP,
  Liste,
  TextPBlock,
  TextPContact,
  TextPContactName,
  TextPContactTelMail
} from "./style";

export const DataPrivacyPage = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 899);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 899);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <PageWrapper>
      <Pagehead
        isDesktop={isDesktop}
        img={isDesktop ? imprintImg : imprintImgMobile}
        headtext={constants.headText}
        secondHeadtext={constants.headSubtext}
        width={isDesktop ? "85%" : "95%"}
        imageAlt="Krücken"
      />
      <TextWrapper>
        <DataH1>1. Datenschutz auf einen Blick</DataH1>
        <DataH3>Allgemeine Hinweise</DataH3>{" "}
        <TextP>
          Die folgenden Hinweise geben einen einfachen Überblick darüber, was
          mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
          besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
          persönlich identifiziert werden können. Ausführliche Informationen zum
          Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
          Datenschutzerklärung.{" "}
        </TextP>
        <DataH3> Datenerfassung auf dieser Website</DataH3>
        <strong>
          Wer ist verantwortlich für die Datenerfassung auf dieser Website?{" "}
        </strong>
        <TextP>
          {" "}
          Die Datenverarbeitung auf dieser Website erfolgt durch den
          Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt
          „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung
          entnehmen.{" "}
        </TextP>{" "}
        <strong>Wie erfassen wir Ihre Daten?</strong>
        <TextP>
          {" "}
          Wir verwenden auf der Seite keine Cookies und erfassen auch sonst
          keine Daten von Ihnen mit ausnahme der IP- Adresse. Die IP-Adresse
          wird nicht protokolliert, sie dient lediglich dazu technisch Inhalte
          bei Ihnen bereitzustellen. Die IP Adresse wird nur zur Verarbeitung
          genutzt. Es erfolgt jedoch keine Speicherung oder Auswertung.
        </TextP>{" "}
        <strong> Wofür nutzen wir Ihre Daten? </strong>{" "}
        <TextP>
          Die Daten werden erhoben, um eine fehlerfreie Bereitstellung der
          Website zu gewährleisten.
        </TextP>{" "}
        <strong> Welche Rechte haben Sie bezüglich Ihrer Daten? </strong>
        <TextP>
          Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
          Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
          erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung
          dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
          Datenverarbeitung erteilt haben, können Sie diese Einwilligung
          jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
          unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
          Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Hierzu sowie
          zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an
          uns wenden.{" "}
        </TextP>
        <strong>Analyse-Tools und Tools von Drittanbietern</strong>{" "}
        <TextP>Wir verwenden keine Analysetools. </TextP>
        <DataH1>2. Externes Hosting</DataH1>{" "}
        <TextP>
          {" "}
          Diese Website wird bei einem externen Dienstleister gehostet (Hoster).{" "}
          Die personenbezogenen Daten, die auf dieser Website erfasst werden,
          werden auf den Servern des Hosters verarbeitet. Hierbei handelt es
          sich um die IP-Adresse.{" "}
        </TextP>
        <TextP>
          {" "}
          Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
          gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1
          lit. b DSGVO) und im Interesse einer sicheren, schnellen und
          effizienten Bereitstellung unseres Online-Angebots durch einen
          professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
        </TextP>{" "}
        <TextP>
          {" "}
          Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
          Erfüllung seiner Leistungspflichten erforderlich ist und unsere
          Weisungen in Bezug auf diese Daten befolgen.
        </TextP>{" "}
        <strong>Wir setzen folgenden Hoster ein:</strong>
        <TextP>OEV Online Dienste GmbH</TextP>
        <TextP>Hansaallee 183 </TextP>
        <TextP>40549 Düsseldorf </TextP>
        <TextP>
          OEV Online Dienste GmbH nutzt zum Zwecke des Hostings als
          Subunternehmer die Amazon Web Services EMEA SARL, 38 Avenue John F.
          Kennedy, 1855 Luxemburg (nachfolgend: AWS). Wenn Sie unsere Website
          besuchen, werden Ihre personenbezogenen Daten auf den Servern von AWS
          verarbeitet. Hierbei können auch personenbezogene Daten an das
          Mutterunternehmen von AWS in die USA übermittelt werden. Die
          Datenübertragung in die USA wird auf die EU-Standardvertragsklauseln
          gestützt. Details finden Sie hier:
          <TextP>
            <a href="https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum">
              https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/
            </a>
            .
          </TextP>
        </TextP>
        <TextP>
          Weitere Informationen entnehmen Sie der Datenschutzerklärung von AWS:
          <TextP>
            <a href=" https://aws.amazon.com/de/privacy/?nc1=f_pr">
              https://aws.amazon.com/de/privacy/?nc1=f_pr
            </a>
            .
          </TextP>
        </TextP>{" "}
        <DataH3>Auftragsverarbeitung</DataH3>{" "}
        <TextP>
          Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem
          genannten Hosting-Anbieter geschlossen. Hierbei handelt es sich um
          einen datenschutzrechtlich vorgeschriebenen Vertrag, der
          gewährleistet, dass dieser die personenbezogenen Daten unserer
          Websitebesucher nur nach unseren Weisungen und unter Einhaltung der
          DSGVO verarbeitet.
        </TextP>{" "}
        <DataH1> 3. Allgemeine Hinweise und Pflichtinformationen</DataH1>{" "}
        <DataH3>Datenschutz</DataH3>
        <TextP>
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
          sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
          entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung. Wenn Sie diese Website benutzen, werden
          verschiedene personenbezogene Daten erhoben. Personenbezogene Daten
          sind Daten, mit denen Sie persönlich identifiziert werden können. Die
          vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben
          und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck
          das geschieht. Wir weisen darauf hin, dass die Datenübertragung im
          Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken
          aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
          Dritte ist nicht möglich.{" "}
        </TextP>{" "}
        <DataH3>Hinweis zur verantwortlichen Stelle</DataH3>
        <TextP>
          Die verantwortliche Stelle für die Datenverarbeitung auf dieser
          Website ist:
        </TextP>{" "}
        <TextP>
          E-Mail:{" "}
          <a href="mailto:service@dreiklang-gmbh.info">
            service@dreiklang-gmbh.info
          </a>
        </TextP>{" "}
        <StrongP>Geschäftsführer:</StrongP>
        <Liste>
          <li>Peter Lemke</li>
          <li>Dr. med. Ingo Liebert </li>
          <li>Meik Jones</li> <li>Christoph Wolters</li>
        </Liste>
        <TextP>
          Verantwortliche Stelle ist die natürliche oder juristische Person, die
          allein oder gemeinsam mit anderen über die Zwecke und Mittel der
          Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen
          o. Ä.) entscheidet.
        </TextP>{" "}
        <DataH3>Speicherdauer</DataH3>
        <TextP>
          Soweit innerhalb dieser Datenschutzerklärung keine speziellere
          Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
          bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie
          ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung
          zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern
          wir keine anderen rechtlich zulässigen Gründe für die Speicherung
          Ihrer personenbezogenen Daten haben (z. B. steuer- oder
          handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall
          erfolgt die Löschung nach Fortfall dieser Gründe.
        </TextP>
        <DataH3>Datenschutzbeauftragter</DataH3>
        <TextP>
          Wir haben für unser Unternehmen einen Datenschutzbeauftragten
          bestellt. Dieser ist zu erreichen unter der o.g. Briefadresse mit dem
          Zusatz „Datenschutzbeauftragter“ oder unter
        </TextP>{" "}
        <TextP>
        <TextPContactName>Dipl.-Betriebswirt Arno Kindler</TextPContactName>
          <TextPContact> Waterstroate 32,</TextPContact>
          <TextPContact> 48231 Warendorf</TextPContact>
          <TextPContactTelMail>Telefon: 02581/21 16</TextPContactTelMail>
          <TextPContact> Fax: 02581/63 33 24</TextPContact>
          <TextPContact>
            E-Mail:{" "}
            <a href="mailto:datenschutz@arno-kindler.de">
              datenschutz@arno-kindler.de
            </a>
          </TextPContact>
        </TextP>{" "}
        <DataH3>
          Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten
        </DataH3>{" "}
        <TextP>
          Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA
          oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten. Wenn
          diese Tools aktiv sind, können Ihre personenbezogenen Daten in diese
          Drittstaaten übertragen und dort verarbeitet werden. Wir weisen darauf
          hin, dass in diesen Ländern kein mit der EU vergleichbares
          Datenschutzniveau garantiert werden kann. Beispielsweise sind
          US-Unternehmen dazu verpflichtet, personenbezogene Daten an
          Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener
          hiergegen gerichtlich vorgehen könnten. Es kann daher nicht
          ausgeschlossen werden, dass US-Behörden (z. B. Geheimdienste) Ihre auf
          US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten,
          auswerten und dauerhaft speichern. Wir haben auf diese
          Verarbeitungstätigkeiten keinen Einfluss.{" "}
        </TextP>{" "}
        <DataH3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</DataH3>
        <TextP>entfällt, da keine Daten erhoben werden.</TextP>
        <DataH3>
          {" "}
          Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
          gegen Direktwerbung (Art. 21 DSGVO)
        </DataH3>{" "}
        <TextP>
          WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER
          F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH
          AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
          PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN
          AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE
          RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE
          DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR
          IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES
          SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE
          VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN
          ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG
          ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS.
          1 DSGVO). WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM
          DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT
          WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER
          DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS
          PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT.
          WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN
          ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET
          (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).{" "}
        </TextP>
        <DataH3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</DataH3>
        <TextP>
          {" "}
          Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
          Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
          Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
          oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
          besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
          gerichtlicher Rechtsbehelfe.
        </TextP>
        <TextP>
          Die zuständige Aufsichtsbehörde hinsichtlich von
          datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte
          Nordrhein-Westfalen. Eine Liste der jeweiligen Beauftragten für den
          Datenschutz sowie die konkreten Kontaktdaten können folgendem Link
          entnommen werden:
        </TextP>
        <TextP>
          <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">
            https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
          </a>
        </TextP>
        <DataH3>Recht auf Datenübertragbarkeit</DataH3>
        <TextP>
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
          oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
          oder an einen Dritten in einem gängigen, maschinenlesbaren Format
          aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an
          einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es
          technisch machbar ist.{" "}
        </TextP>{" "}
        <DataH3>SSL- bzw. TLS-Verschlüsselung</DataH3>
        <TextP>
          Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
          Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder
          Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
          TLSVerschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran,
          dass die Adresszeile des Browsers von „http://“ auf „https://“
          wechselt und an dem Schloss-Symbol in Ihrer Browserzeile. Wenn die
          SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie
          an uns übermitteln, nicht von Dritten mitgelesen werden.
        </TextP>{" "}
        <DataH3>Auskunft, Löschung und Berichtigung</DataH3>
        <TextP>
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
          das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
          personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck
          der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
          Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
          personenbezogene Daten können Sie sich jederzeit an uns wenden.
        </TextP>
        <DataH3> Recht auf Einschränkung der Verarbeitung</DataH3>
        <TextP>
          Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
          an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
          folgenden Fällen:
        </TextP>{" "}
        <TextPBlock>
          Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen
          Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu
          überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
          Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen.
        </TextPBlock>{" "}
        <TextPBlock>
          Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
          geschah/geschieht, können Sie statt der Löschung die Einschränkung der
          Datenverarbeitung verlangen.
        </TextPBlock>{" "}
        <TextPBlock>
          Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie
          jedoch zur Ausübung, Verteidigung oder Geltendmachung von
          Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung
          die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen.
        </TextPBlock>{" "}
        <TextPBlock>
          Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben,
          muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen
          werden. Solange noch nicht feststeht, wessen Interessen überwiegen,
          haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen.
        </TextPBlock>{" "}
        <TextPBlock>
          Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt
          haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
          Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
          von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
          natürlichen oder juristischen Person oder aus Gründen eines wichtigen
          öffentlichen Interesses der Europäischen Union oder eines
          Mitgliedstaats verarbeitet werden.
        </TextPBlock>{" "}
        <DataH1>4. Datenerfassung auf dieser Website</DataH1>{" "}
        <DataH3>Cookies</DataH3>{" "}
        <TextP>Wir verzichten auf die Nutzung von Cookies.</TextP>
        <DataH3>Server-Log-Dateien</DataH3>
        <TextP>
          Der Provider der Seiten erhebt und speichert automatisch Informationen
          in sogenannten Server-LogDateien, die Ihr Browser automatisch an uns
          übermittelt.
        </TextP>{" "}
        <TextP>Dies sind:</TextP>
        <TextPBlock>Browsertyp und Browserversion</TextPBlock>
        <TextPBlock>verwendetes Betriebssystem</TextPBlock>
        <TextPBlock>Referrer URL</TextPBlock>
        <TextPBlock>Hostname des zugreifenden Rechners</TextPBlock>
        <TextPBlock>Uhrzeit der Serveranfrage</TextPBlock>
        <TextPBlock>IP-Adresse</TextPBlock>
        <TextP>
          Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
          vorgenommen. Die Erfassung dieser Daten erfolgt auf Grundlage von Art.
          6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
          Interesse an der technisch fehlerfreien Darstellung und der
          Optimierung seiner Website – hierzu müssen die Server-Log-Files
          erfasst werden.
        </TextP>
      </TextWrapper>
    </PageWrapper>
  );
};
